const VISIBLE_MOVIES_IN_ROW_320_500 = 1;
const VISIBLE_ROW_MOVIES_320_500 = 5;
const VISIBLE_ADD_ROW_MOVIES_320_500 = 2;

const VISIBLE_MOVIES_IN_ROW_501_768 = 2;
const VISIBLE_ROW_MOVIES_501_768 = 4;
const VISIBLE_ADD_ROW_MOVIES_501_768 = 1;

const VISIBLE_MOVIES_IN_ROW_769_1280 = 3;
const VISIBLE_ROW_MOVIES_769_1280 = 4;
const VISIBLE_ADD_ROW_MOVIES_769_1280 = 1;

export {
  VISIBLE_MOVIES_IN_ROW_320_500,
  VISIBLE_ROW_MOVIES_320_500,
  VISIBLE_ADD_ROW_MOVIES_320_500,
  VISIBLE_MOVIES_IN_ROW_501_768,
  VISIBLE_ROW_MOVIES_501_768,
  VISIBLE_ADD_ROW_MOVIES_501_768,
  VISIBLE_MOVIES_IN_ROW_769_1280,
  VISIBLE_ROW_MOVIES_769_1280,
  VISIBLE_ADD_ROW_MOVIES_769_1280,
};
